html {
    box-sizing: border-box;
  }
  
body {
  /* margin-top: 80px; */
  background-color: rgb(185, 215, 255);
}
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  /* The News-Articles Container */
  .News-Articles {
    margin: 2em 2em;
    column-gap: 1.5em;
  }
  
  /* News-Articles on large screens */
  @media only screen and (min-width: 1131px) {
    .News-Articles {
      column-count: 4;
    }
  }
  
  /* News-Articles on medium-sized screens */
  @media only screen and (max-width: 1130px) and (min-width: 768px) {
    .News-Articles {
      column-count: 3;
    }
  }
  
  /* News-Articles on small screens */
  @media only screen and (max-width: 767px) and (min-width: 540px) {
    .News-Articles {
      column-count: 2;
    }
  }

/* body {
  background-color: #cccccc;
  color: #333333;
}
.News-Wrapper {
  padding: 1.5em;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}
.News-Articles {
  columns: 1;
  column-gap: 10px;
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .News-Articles {
    columns: 2;
  }
}
@media only screen and (min-width: 1024px) {
  .News-Articles {
    columns: 3;
  }
} */
  