  /* The News-Articles Brick */
.Card {
    display: inline-block;
    background: rgb(209, 230, 255);
    /* padding: 1em; */
    margin: 0 0 1.5em;
    border-radius: 7px;
    /* box-shadow: 1px 2px 4px rgba(255, 255, 255, 0.5); */
    /* border: 1px solid gray; */
  }

.Card-Content {
  overflow: hidden;
}

.Card-Image-Wrapper {
  height: 200px;
  overflow: hidden;
}

.Card-Image {
    transition: transform 1s ease-in;
    width: 100%;
    height: 200px;
    background-attachment: fixed;
    border-radius: 7px 7px 0 0;
    overflow: hidden;
}

.Card-Image:hover {
  transform: scale(1.5);
}

.Card-Text-Section {
  padding: 13px;
}

.Card-Title {
  font-weight: bold;
  text-decoration: none;
  border: 0 0 1px 0, solid, gray;
}

.Card-Title:hover {
  text-decoration: underline;
}


/* 

.Card-Image {
    width: 100%;
    height: 200px;
    background-attachment: fixed;
} 

.Card {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  max-width: 320px;
}
.Card,
.Card-Content {
  border-radius: 4px;
  overflow: hidden;
}
.Card {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
  transition: filter 0.25s ease-in-out;
}
.Card:hover {
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.3));
}
.Card-Content {
  overflow: hidden;
}
.Card {
  color: #111111;
  background-color: #f9f9f9;
}
.Card-Title,
.Card-Description {
  margin: 0;
}
.Card-Title {
  font-weight: 700;
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
}
.Card-Description {
  padding: 1.5rem;
  font-size: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
} 
*/