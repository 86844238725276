.Navbar {
  overflow: hidden;
  background-color: #333;
}

.Navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.Dropdown {
  float: left;
  overflow: hidden;
}

.Dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.Navbar a:hover,
.Dropdown:hover .dropbtn:hover {
  background-color: red;
}

.Dropdown-Content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Dropdown-Content label {
  overflow: auto;
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.Dropdown-Content label:hover {
  background-color: darkgray;
}

.Dropdown-Content a:hover {
  background-color: #ddd;
}

.Dropdown:hover .Dropdown-Content {
  display: block;
}

/* ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  width: 100%;
}

ul > li:hover {
    background-color: rgb(73, 73, 73);
}

li {
  float: left;
}

li > * {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: 700;
}

.Dropdown-Main:hover .Dropdown-Wrapper {
    display: block;
    color: black;
}

.Dropdown-Item {
    display: block;
}

.Dropdown-Wrapper {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}
 */
